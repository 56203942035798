import '../css/home.css';

import './app/kernel';
import * as glide from './module/glide';

const glideScreen = glide.create('.glide-h-screen', {
  autoplay: 4000,
  type: 'carousel'
});

const glideReviews = glide.create('.glide-reviews');

document.addEventListener('DOMContentLoaded', () => {
  glideScreen.mount();
  glideReviews.mount();
});
