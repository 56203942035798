import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nav", "close", "open"];

  toggle() {
    this.navTarget.classList.toggle('hidden');

    if(false == this.navTarget.classList.contains('hidden')) {
      this.openTarget.classList.add('hidden');
      this.closeTarget.classList.remove('hidden');
    }
    else {
      this.openTarget.classList.remove('hidden');
      this.closeTarget.classList.add('hidden');
    }
  }

}
